.scene-1 {
  overflow: hidden;
}

@media screen and (min-width: 1200px) {
  .img-row {
    height: 420px;
  }
}

@media screen and (min-width: 1800px) {
  .img-row {
    height: 520px;
  }
}

.row-img-container {
  background-image: url("/assets/app/scene-1/row-bk.png");
  background-position: center;
  background-size: cover;
}

.row-text-container {
  background-color: #202e25;
  position: relative;
}

.scene-1-row-text {
  width: 130%;
  position: absolute;
  bottom: -20px;
  margin-left: -135px;
}

.scene-1-content {
  justify-content: center;
  padding: 4rem 0;
  position: relative;
  --bs-gutter-x: -130px;
}

.scene-1-content > div {
  padding-left: 0;
  padding-right: 0;
}

.scene-1-text-mobile {
  display: none;
}

.scene-1-content > div:first-child {
  margin-right: -50px;
}
.scene-1-content > div:nth-child(4) {
  margin-left: -50px;
}

.scene-1-phone {
  width: 100%;
  height: auto;
}

.scene-text-container {
  width: fit-content;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 8rem;
}

.arrow {
  position: absolute;
  right: 0;
  bottom: 20%;
  height: 19px;
  width: 87px;
}

@media screen and (min-width: 1800px) {
  .scene-text-container {
    bottom: 3%;
    left: 3%;
  }
}

@media screen and (min-width: 2200px) {
  .scene-text-container {
    bottom: 5%;
    left: 7%;
  }
}

@media screen and (max-width: 1300px) {
  .scene-text-container {
    left: -2%;
  }
}

@media screen and (max-width: 1200px) {
  .row-img-container {
    /* display: none; */
    height: 400px;
    width: 100% !important;
  }

  .row-text-container {
    width: 100% !important;
    padding-top: 200px;
    height: auto;
  }

  .scene-1-row-text {
    position: absolute;
    width: 100%;
    bottom: -20px;
    margin-left: 0;
  }
}

@media screen and (max-width: 1150px) {
  .arrow {
    position: absolute;
    right: 0;
    bottom: 25%;
    height: 10px;
    width: 35px;
  }

  .scene-text-container {
    left: 5%;
  }
}

@media screen and (max-width: 800px) {
  .scene-1-content {
    --bs-gutter-x: 0;
  }

  .scene-text-container {
    bottom: -7%;
    left: 4%;
  }
}

@media screen and (max-width: 700px) {
  .scene-text-container {
    left: 1%;
  }

  .row-img-container {
    height: 200px;
  }

  .scene-1-row-text {
    bottom: -10px;
  }
}

@media screen and (max-width: 575px) {
  .scene-1-content {
    padding: 1rem 0 0 0;
  }

  .scene-1-content > div:first-child {
    margin-right: 0;
  }
  .scene-1-content > div:nth-child(4) {
    margin-left: 0;
  }

  .scene-1-phone {
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .scene-text-container {
    display: none;
  }

  .scene-1-text-mobile {
    display: block;
    text-align: center;
    position: relative;
  }
  .arrow {
    top: -75%;
    left: 20%;
    transform: rotate(-60deg);
  }
}

@media screen and (max-width: 450px) {
  .row-text-container {
    padding-top: 80px;
  }

  .scene-1-phone {
    width: 70%;
  }

  .arrow {
    top: -65%;
    left: 15%;
  }
}

.store-row {
  padding: 50px 0 50px 0;
  background: linear-gradient(180deg, #9cb9be 0%, #cde0e4 100%);
  position: relative;
}

.button-container {
  text-align: center;
  z-index: 100;
}

.store-button-img {
  width: 17%;
  height: auto;
  cursor: pointer;
}

.apple-store {
  margin-right: 2rem;
}

.store-text {
  position: absolute;
  left: 55%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 1800px) {
  .store-text {
    left: 62%;
  }
}

@media screen and (max-width: 750px) {
  .store-text {
    position: initial;
    left: initial;
    top: initial;
    transform: initial;
    text-align: center;
  }

  .store-row {
    padding: 25px 0 25px 0;
  }

  .store-button-img {
    width: 30%;
  }
}

@media screen and (max-width: 375px) {
  .store-row {
    padding: 25px 0 15px 0;
  }
}

@media screen and (max-width: 320px) {
  .apple-store {
    margin-right: 1rem;
  }
}
