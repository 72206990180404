.trademark {
  width: 100%;
  font-family: "pluto sans";
  text-align: center;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: -0.01em;
  padding-bottom: 10px;
}

.trademark > p {
  margin-bottom: 0;
}

.scene-4 {
  text-align: center;
  background-color: #e6e7e8;
  padding-top: 3rem;
}

.scene-4 > p {
  font-size: 43px;
}

.responsive-video-container {
  position: relative;
  overflow: hidden;
  width: 85%;
  padding-top: 56.25%;
  margin-left: auto;
  margin-right: auto;
}

.responsive-video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 670px) {
  .scene-4 > p {
    font-size: 12px;
  }

  .trademark {
    font-size: 8px;
  }
}
