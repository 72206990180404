nav {
  position: relative;
  width: 100%;
  background: rgba(20, 23, 24, 1);
  backdrop-filter: blur(3px);
  height: 100px;
  display: flex;
  align-items: center;
  font-family: "pluto sans light dpd";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  justify-content: flex-start;
  letter-spacing: -0.015em;
  border-bottom: 0.4px solid #808285;
  z-index: 99;
}

.nav-text-container {
  /* border: red;
  border-radius: 1px;
  border-style: solid; */
  width: 70%;
  display: flex;
  justify-content: center;
}

.icon,
.icon-cross {
  display: none;
}

a {
  color: #808285;
  margin-left: 2rem;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: #ffffff;
}

.dpd-cube {
  height: 44px;
  width: 36px;
  /* margin-left: 28px; */
  margin-left: 6rem;
  cursor: pointer;
}

.dpd-title {
  margin-left: 24px;
  margin-bottom: 0;
  margin-top: 0;
  cursor: pointer;
}

.nav-toTop-container {
  display: none;
  position: fixed;
  z-index: 99;
  bottom: 0;
  right: 0;
  margin-right: 5%;
  margin-bottom: 2%;
  cursor: pointer;
}

.toTop-img {
  color: #808285;
}

@media screen and (max-width: 1035px) {
  .nav-text-container {
    display: none;
  }

  .icon {
    display: flex;
    margin-right: 20px;
    margin-inline-start: auto;
  }
}

@media screen and (max-width: 1035px) {
  nav {
    justify-content: flex-start;
    border-bottom: 0.2px solid #808285;
    height: 65px !important;
  }

  .nav-text-container.responsive {
    position: absolute;
    right: 0;
    margin-top: 66px;
    width: 170px;
    align-self: baseline;
    display: flex;
    flex-wrap: wrap;
    background: rgba(0, 0, 0, 0.87);
    backdrop-filter: blur(4px);
    font-size: 12px;
    z-index: 1;
    text-align: left;
    padding: 15px;
  }
  nav a {
    width: 100%;
    margin-top: 10%;
  }

  .icon.responsive {
    display: none;
  }

  .icon-cross.responsive {
    display: flex;
    margin-right: 20px;
    margin-inline-start: auto;
  }

  a {
    margin-left: 2px;
  }

  .dpd-cube {
    height: 26px;
    width: 21px;
    margin-left: 28px;
  }
  .dpd-title {
    margin-left: 13px;
    letter-spacing: -0.05em;
  }
}

@media screen and (max-width: 600px) {
  .toTop-img {
    height: 40px;
    width: 40px;
  }
  .nav-toTop-container {
    margin-right: 5%;
  }
}
