.scene-2 {
  overflow: hidden;
}

.scene-2-img-row {
  margin-bottom: 1rem;
}

@media screen and (min-width: 1200px) {
  .scene-2-img-row {
    height: 450px;
  }
}

.scene-2-row-img {
  background-image: url("/assets/app/scene-2/row-image.png");
  background-color: #c4c4c4;
  background-blend-mode: multiply;
}

.scene-2-row-img-2 {
  background-image: url("/assets/app/scene-2/row-img-2.png");
  background-color: #c4c4c4;
  background-blend-mode: multiply;
}

.row-2 {
  margin-top: 1rem;
}
.scene-2-heading {
  padding-bottom: 1rem;
}

.scene-2-row-text {
  font-size: 170px;
  line-height: 135px;
  position: absolute;
  bottom: -20px;
}

.scene-2-content {
  background-color: #e6e7e8;
  padding: 7.5% 10%;
}

.scene-2-img {
  width: 100%;
  height: auto;
}

.text-row {
  flex: 0 0 auto;
  width: 74.5%;
}

.scene-2-img-container {
  --bs-gutter-x: 10px;
  --bs-gutter-y: 10px;
}

@media screen and (max-width: 1200px) {
  .scene-2-row-text {
    font-size: 120px;
    line-height: 100px;
    position: absolute;
    bottom: -10px;
  }
  .scene-2-img-row {
    flex-direction: column-reverse;
    margin-bottom: 0;
  }
  .scene-2-row-img,
  .scene-2-row-img-2 {
    background-position: initial;
  }

  .text-2 {
    font-size: 100px;
  }
}

@media screen and (max-width: 670px) {
  .scene-2-row-text {
    font-size: 70px;
    line-height: 55px;
  }
  .text-2 {
    font-size: 60px;
    bottom: -15px;
  }

  .mobile-background {
    background-color: black;
  }
  .scene-2-heading {
    text-align: center;
  }

  .scene-2-content > .row {
    justify-content: center;
  }

  .scene-2-img {
    width: 75%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 400px) {
  .scene-2-row-text {
    font-size: 65px;
    line-height: 55px;
  }

  .text-2 {
    font-size: 55px;
  }
}

@media screen and (max-width: 320px) {
  .scene-2-row-text {
    font-size: 55px;
    line-height: 45px;
  }

  .text-2 {
    font-size: 45px;
  }

  .row-text-container {
    padding-top: 65px;
  }
}
