nav {
  border-bottom: none;
}

.row-heading {
  font-family: "pluto sans";
  font-style: normal;
  font-weight: bold;
  font-size: 190px;
  line-height: 145px;
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 0.3);
}

br {
  content: initial;
}
br::after {
  content: initial;
}

.dpd-black {
  color: #414042;
}

.app-text {
  font-family: "pluto sans";
  font-style: normal;
  font-weight: normal;
  font-size: 43.2187px;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
}

@media screen and (max-width: 1200px) {
  .row-heading {
    font-size: 150px;
    line-height: 125px;
  }
}

@media screen and (max-width: 670px) {
  .row-heading {
    font-size: 80px;
    line-height: 65px;
    font-family: "pluto sans bold";
    font-weight: normal;
  }

  .app-text {
    font-size: 13px;
    line-height: 25px;
  }
}

@media screen and (max-width: 400px) {
  .row-heading {
    font-size: 70px;
    line-height: 60px;
  }
}

@media screen and (max-width: 320px) {
  .row-heading {
    font-size: 65px;
    line-height: 57px;
  }
}
