.scene-3 {
  padding-top: 3rem;
}

.row-mobile {
  display: none;
}

.scene-3 > .row {
  justify-content: center;
  position: relative;
}

.scene-3-img,
.scene-3-img-new {
  width: 100%;
  height: auto;
}

.scene-3-text-container {
  position: absolute;
  width: fit-content;
  left: 2%;
  bottom: 25%;
}

.scene-3-arrow {
  height: 19px;
  width: 70px;
  position: absolute;
  right: -30%;
  bottom: 20%;
}

.scene-3-anchor:hover {
  color: #414042;
}

@media screen and (min-width: 1800px) {
  .scene-3-text-container {
    left: 5%;
  }
}

@media screen and (min-width: 2000px) {
  .scene-3-text-container {
    left: 7%;
  }
}

@media screen and (min-width: 2200px) {
  .scene-3-text-container {
    left: 8%;
  }
}

@media screen and (min-width: 2500px) {
  .scene-3-text-container {
    left: 10%;
  }
}

@media screen and (max-width: 1380px) {
  .scene-3-arrow {
    right: -25%;
  }
}

@media screen and (max-width: 1300px) {
  .scene-3-arrow {
    right: -20%;
  }
}

@media screen and (max-width: 1250px) {
  .scene-3-arrow {
    height: 12px;
    width: 54px;
    right: -12%;
  }
}

@media screen and (max-width: 1150px) {
  .scene-3-arrow {
    bottom: 30%;
    right: -30%;
  }

  .scene-3-text-container {
    left: 7%;
  }
}

@media screen and (max-width: 1080px) {
  .scene-3-text-container {
    left: 5%;
  }
}

@media screen and (max-width: 950px) {
  .scene-3-text-container {
    left: 2%;
  }
}

@media screen and (max-width: 780px) {
  .scene-3-arrow {
    height: 8px;
    width: 30px;
    right: -15%;
  }
}

@media screen and (max-width: 680px) {
  .scene-3-img {
    display: none;
  }

  .scene-3-text-container {
    display: none;
  }

  .desktop-row {
    display: none;
  }
  .row-mobile {
    display: flex;
  }
  .scene-3-mobile-col {
    padding: 0;
  }
}

@media screen and (max-width: 480px) {
  .scene-3 {
    padding-top: 20px;
  }
}
